<template>
  <div
    v-if="group && field && fieldData && fieldContent"
    :id="`form-input-${group}-${field}`"
    :class="['form-input', {
      [`form-input--${fieldData.type}`]: fieldData.type
    }]"
    :data-field="field"
    :data-group="group"
    @reset="reset"
    @update="update"
  >
    <!-- Radio -->
    <zg-radio
      v-if="fieldData.type === 'radio' || fieldData.type === 'radio-multiple'"
      v-model="modelValue"
      :disabled="fieldData.disabled || lockedField"
      :error="error"
      :full-width="isSingleStep && !projectValentin || props.fullWidth"
      :horizontal="isSingleStep && !projectValentin || props.horizontal"
      :invalid="invalid"
      :label="content.label"
      :multiple="fieldData.type === 'radio-multiple'"
      :name="`${group}-${field}`"
      :no-margin="props.noMargin"
      :options="options"
      :read-only="readOnly"
      :show-required="props.required"
      :size="radioSize"
      :tooltip="content.tooltip"
      :valid="valid"
      :value="fieldData.value"
      :version="fieldData.version"
      :vertical="props.vertical"
      @input="inputTriggered($event)"
    />

    <!-- Checkbox -->
    <zg-checkbox
      v-else-if="fieldData.type === 'checkbox'"
      v-model="modelValue"
      :disabled="fieldData.disabled"
      :error="error"
      :full-width="isSingleStep || props.fullWidth"
      :invalid="invalid"
      :name="`${group}-${field}`"
      :no-margin="props.noMargin"
      :overlay="projectValentin"
      :read-only="readOnly"
      :show-required="props.required"
      :smaller-label="props.smallerLabel"
      :tooltip="content.tooltip"
      :valid="valid"
      :value="fieldData.value ? 'on' : 'off'"
      @change="inputTriggered($event)"
    >
      <span v-html="content.label" />
    </zg-checkbox>

    <!-- Date select -->
    <zg-date-select
      v-else-if="fieldData.type === 'date'"
      v-model="modelValue"
      :disabled="fieldData.disabled || lockedField"
      :end-year="fieldData.options.endYear"
      :error="error"
      :full-width="isSingleStep && !projectValentin || props.fullWidth"
      :invalid="invalid"
      :label="content.label"
      :language="language"
      :name="`${group}-${field}`"
      :no-margin="props.noMargin"
      :read-only="readOnly"
      :show-required="props.required"
      :size="size"
      :start-year="fieldData.options.startYear"
      :tooltip="content.tooltip"
      :valid="valid"
      :value="fieldData.value"
      @blur="blurTriggered"
      @focus="focusTriggered"
      @input="inputTriggered($event)"
    />

    <!-- Select -->
    <zg-select
      v-else-if="fieldData.type === 'select' && !projectValentin"
      v-model="modelValue"
      :attrs="fieldData.attrs"
      :disabled="fieldData.disabled || lockedField"
      :error="error"
      :full-width="isSingleStep || props.fullWidth"
      :invalid="invalid"
      :label="content.label"
      :name="`${group}-${field}`"
      :no-margin="props.noMargin"
      :options="options"
      :overlay="projectValentin"
      :read-only="readOnly"
      :show-required="props.required"
      :tooltip="content.tooltip"
      :valid="valid"
      :value="fieldData.value"
      @blur="blurTriggered"
      @focus="focusTriggered"
      @input="inputTriggered($event)"
    />

    <zg-select-pv
      v-else-if="fieldData.type === 'select' && projectValentin"
      v-model="modelValue"
      :attrs="fieldData.attrs"
      :disabled="fieldData.disabled || lockedField"
      :error="error"
      :full-width="isSingleStep || props.fullWidth"
      :helper-text="helperText"
      :invalid="invalid"
      :label="content.label"
      :name="`${group}-${field}`"
      :no-margin="props.noMargin"
      :options="options"
      :placeholder="null"
      :read-only="readOnly"
      :show-required="false"
      :size="size"
      :tooltip="projectValentinTooltip"
      :valid="valid"
      :value="fieldData.value"
      @blur="blurTriggered"
      @focus="focusTriggered"
      @input="inputTriggered($event)"
    />

    <!-- Textarea -->
    <zg-textarea
      v-else-if="fieldData.type === 'textarea'"
      v-model="modelValue"
      :attrs="fieldData.attrs"
      :disabled="fieldData.disabled || lockedField"
      :error="error"
      :full-width="isSingleStep || props.fullWidth"
      :invalid="invalid"
      :label="content.label"
      :name="`${group}-${field}`"
      :no-margin="props.noMargin"
      :read-only="readOnly"
      :rows="props.rows"
      :show-required="props.required"
      :tooltip="content.tooltip"
      :valid="valid"
      :value="fieldData.value"
      @blur="blurTriggered"
      @focus="focusTriggered"
      @input="inputTriggered($event)"
    />

    <!-- Input -->
    <zg-input
      v-else-if="!projectValentin"
      v-model="modelValue"
      :attrs="fieldData.attrs"
      :autocomplete="autocomplete"
      :disabled="fieldData.disabled || lockedField"
      :email-warning-language="MARKET"
      :error="error"
      :full-width="isSingleStep || props.fullWidth"
      :invalid="invalid"
      :label="content.label"
      :lookup="lookup"
      :name="`${group}-${field}`"
      :no-margin="props.noMargin"
      :overlay="projectValentin"
      :read-only="readOnly"
      :show-required="props.required"
      :tooltip="content.tooltip"
      :type="fieldData.type"
      :valid="valid"
      :value="fieldData.value"
      @blur="blurTriggered"
      @focus="focusTriggered"
      @input="inputTriggered($event)"
      @toggleLookup="resetLookup(group)"
    />

    <zg-input-pv
      v-else
      v-model="modelValue"
      :attrs="fieldData.attrs"
      :autocomplete="autocomplete"
      :disabled="fieldData.disabled || lockedField"
      :email-warning-language="MARKET"
      :error="error"
      :full-width="props.fullWidth"
      :helper-text="helperText"
      :invalid="invalid"
      :label="content.label"
      :lookup="lookup"
      :name="`${group}-${field}`"
      :no-margin="props.noMargin"
      :read-only="readOnly"
      :show-required="false"
      :size="size"
      :tooltip="projectValentinTooltip"
      :type="fieldData.type"
      :unit="unit"
      :valid="valid"
      :value="fieldData.value"
      @blur="blurTriggered"
      @focus="focusTriggered"
      @input="inputTriggered($event)"
      @toggleLookup="resetLookup(group)"
    />
  </div>
</template>

<script>
  import { clone, filterOptions, formatField, isArray, isObject, isString, replaceBrandVars, replaceFormFieldContent, setDynamicContent, usableValue } from '@ocp-zmarta/zmarta-cl'
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'FormInput',
    components: {
      ZgCheckbox: () => import(/* webpackChunkName: 'zc/zg-checkbox' */
        '@zc/components/ZgCheckbox/ZgCheckbox'),
      ZgDateSelect: () => import(/* webpackChunkName: 'zc/zg-date-select' */
        '@zc/components/ZgDateSelect/ZgDateSelect'),
      ZgInput: () => import(/* webpackChunkName: 'zc/zg-input' */
        '@zc/components/ZgInput/ZgInput'),
      ZgInputPv: () => import(/* webpackChunkName: 'zc/zg-input-pv' */
        '@zc/components/ZgInputPv/ZgInputPv'),
      ZgRadio: () => import(/* webpackChunkName: 'zc/zg-radio' */
        '@zc/components/ZgRadio/ZgRadio'),
      ZgSelect: () => import(/* webpackChunkName: 'zc/zg-select' */
        '@zc/components/ZgSelect/ZgSelect'),
      ZgSelectPv: () => import(/* webpackChunkName: 'zc/zg-select-pv' */
        '@zc/components/ZgSelectPv/ZgSelectPv'),
      ZgTextarea: () => import(/* webpackChunkName: 'zc/zg-textarea' */
        '@zc/components/ZgTextarea/ZgTextarea')
    },
    props: {
      group: {
        type: String,
        required: true,
        default: null
      },
      field: {
        type: String,
        required: true,
        default: null
      },
      projectValentin: {
        type: Boolean,
        required: false,
        default: false
      },
      helperText: {
        type: String,
        required: false,
        default: null
      },
      size: {
        type: String,
        required: false,
        default: 'default',
        validator: value => ['small', 'medium', 'large', 'full-size', 'default'].includes(value)
      },
      unit: {
        type: String,
        required: false,
        default: null
      }
    },
    data: () => ({
      value: undefined,
      focused: false
    }),
    computed: {
      ...mapGetters('content', ['getContent']),
      ...mapGetters('verify', ['getVerify']),
      ...mapGetters('features', ['getFeatures']),
      ...mapGetters('translations', ['getLocale']),
      ...mapGetters('form', ['getForm']),
      ...mapGetters('cl/application', { clGetApplicationEvents: 'getApplicationEvents' }),
      ...mapGetters('cl/formAlternatives', { clGetFormAlternatives: 'getFormAlternatives' }),
      modelValue: {
        get () {
          const value = this.value ?? this.fieldData?.value
          const filter = this.FILTERS?.[this.fieldData?.filter]

          if (this.focused || !value || !filter) return value

          return filter(value, this.MARKET, this.getLocale)
        },
        set (value) {
          this.value = value
        }
      },
      common () {
        return this.getContent?.common ?? {}
      },
      fieldData () {
        return this.getForm?.[this.group]?.[this.field] ?? {}
      },
      fieldContent () {
        const group = this.group === 'coApplicant' ? 'applicant' : this.group
        return this.getContent?.form?.[group]?.[this.field] ?? {}
      },
      isSwedishWhiteLabel () {
        return this.WHITE_LABEL && this.SWEDEN
      },
      language () {
        return this.getLocale === 'en'
          ? 'en'
          : this.MARKET
      },
      valid () {
        return !!(
          usableValue(this.fieldData?.value) &&
          this.fieldData?.valid &&
          this.fieldData?.visibleValidation &&
          this.fieldData?.touched &&
          !this.fieldData?.disabled &&
          !this.lockedField
        )
      },
      invalid () {
        return !!(
          !this.fieldData?.valid &&
          this.fieldData?.touched &&
          this.fieldData?.visibleValidation &&
          !this.fieldData?.disabled &&
          !this.lockedField
        )
      },
      error () {
        return (
          !this.invalid ||
          (this.getVerify?.[this.field]?.values?.find(item => item.value === this.fieldData?.value)?.valid === false &&
            this.getFeatures?.['show-email-warning-text']?.enabled)
        )
          ? null
          : this.content.error
      },
      props () {
        return {
          required: this.content?.label && this.fieldData?.validation?.required,
          noMargin: !!this.fieldData?.props?.noMargin,
          rows: this.fieldData?.props?.rows,
          fullWidth: !!this.fieldData?.props?.fullWidth,
          horizontal: !!this.fieldData?.props?.horizontal,
          vertical: !!this.fieldData?.props?.vertical,
          smallerLabel: !!this.projectValentin
        }
      },
      readOnly () {
        if (this.CL) {
          return this.clGetApplicationEvents?.finalizeApplication
        }

        return null
      },
      lookup () {
        const noValue = {
          label: '',
          visible: false
        }

        if (this.field !== 'ssn' || this.lockedField) {
          return noValue
        }

        const firstName = this.getForm?.[this.group]?.firstName?.value
        const lastName = this.getForm?.[this.group]?.lastName?.value

        if (!firstName || !lastName) {
          return noValue
        }

        return {
          label: `${firstName} ${this.$options.filters.censorName(lastName)}`,
          visible: true
        }
      },
      content () {
        let labelKey = 'label'
        let errorKey = 'error'
        let tooltipTitleKey = 'tooltipTitle'
        let tooltipTextKey = 'tooltipText'
        let tooltip = null

        if (this.fieldData?.dynamicContent) {
          const dynamicContent = setDynamicContent({
            market: this.MARKET,
            dynamicContent: this.fieldData.dynamicContent,
            form: this.getForm,
            currentGroup: this.group
          })

          labelKey = this.fieldContent?.[dynamicContent?.label]
            ? dynamicContent?.label
            : labelKey
          errorKey = this.fieldContent?.[dynamicContent?.error]
            ? dynamicContent?.error
            : errorKey
          tooltipTitleKey = this.fieldContent?.[dynamicContent?.tooltipTitle]
            ? dynamicContent?.tooltipTitle
            : tooltipTitleKey
          tooltipTextKey = this.fieldContent?.[dynamicContent?.tooltipText]
            ? dynamicContent?.tooltipText
            : tooltipTextKey
        }

        // Check for labels specifically set for any co applicant
        if (this.group === 'coApplicant') {
          const labelCoKey = `${labelKey}Co`
          labelKey = this.fieldContent?.[labelCoKey]
            ? labelCoKey
            : labelKey

          const errorCoKey = `${errorKey}Co`
          errorKey = this.fieldContent?.[errorCoKey]
            ? errorCoKey
            : errorKey

          const tooltipTitleCoKey = `${tooltipTitleKey}Co`
          tooltipTitleKey = this.fieldContent?.[tooltipTitleCoKey]
            ? tooltipTitleCoKey
            : tooltipTitleKey

          const tooltipTextCoKey = `${tooltipTextKey}Co`
          tooltipTextKey = this.fieldContent?.[tooltipTextCoKey]
            ? tooltipTextCoKey
            : tooltipTextKey
        }

        // Check for labels specifically set for white labels
        if (this.isSwedishWhiteLabel) {
          const labelCoKey = `${labelKey}WhiteLabel`
          labelKey = this.fieldContent?.[labelCoKey]
            ? labelCoKey
            : labelKey
        }

        // Get the actual content
        let label = this.fieldContent?.[labelKey]
        let error = this.fieldContent?.[errorKey]
        let tooltipTitle = this.fieldContent?.[tooltipTitleKey]
        let tooltipText = this.fieldContent?.[tooltipTextKey]

        /**
         * Some specific content replacing
         * @type {{tooltipTitle: *, tooltipText: *, label: *, error: *}}
         */
        const replacedContent = replaceFormFieldContent({
          brand: this.BRAND,
          market: this.MARKET,
          locale: this.getLocale,
          content: { label, error, tooltipTitle, tooltipText },
          form: this.getForm
        })

        label = replacedContent?.label
        error = replacedContent?.error
        tooltipTitle = replacedContent?.tooltipTitle
        tooltipText = replacedContent?.tooltipText

        // handle dynamic remaining loanAmount in loanPurpose
        if (this.field === 'loanPurpose' && this.CL) {
          const loanAmount = this.getForm?.global?.loanAmount?.value
          const refinanceAmount = this.getForm?.global?.refinanceAmount?.value

          if (refinanceAmount && loanAmount && loanAmount > refinanceAmount) {
            const newAmount = loanAmount - refinanceAmount
            label = label?.replace('{amount}', this.$options.filters.currency(newAmount, this.MARKET, this.getLocale))
          } else {
            label = this.getContent?.form?.global?.loanPurpose?.label
          }
        }

        // set href:s for links
        if (this.field === 'userAgreement') {
          label = replaceBrandVars({
            brand: this.BRAND,
            market: this.MARKET,
            vertical: this.VERTICAL,
            locale: this.getLocale,
            content: label,
            form: this.getForm
          })
        }

        // Set the tooltip
        if (tooltipTitle || tooltipText) {
          const noTrigger = ['date'].includes(this.field)
          tooltipText = replaceBrandVars({
            brand: this.BRAND,
            market: this.MARKET,
            vertical: this.VERTICAL,
            locale: this.getLocale,
            content: tooltipText,
            form: this.getForm
          })

          tooltip = {
            title: tooltipTitle,
            text: tooltipText,
            trigger: !noTrigger
          }
        }

        return {
          label,
          error,
          tooltip
        }
      },
      options () {
        const options = this.fieldData?.options
        if (!options) return []

        if (isString(options)) {
          let formAlternatives = {}

          if (this.CL) {
            formAlternatives = this.clGetFormAlternatives
          }

          const output = filterOptions({
            market: this.MARKET,
            form: this.getForm,
            group: this.group,
            field: this.field,
            formAlternatives
          })

          return output || []
        } else if (isArray(options) && JSON.stringify(options).indexOf('common') >= 0) {
          let output = clone(options)
          output = output
            .map(opt => ({
              ...opt,
              label: this.replaceOptionLabel(opt.label)
            }))

          return output
        } else if (isObject(options) && JSON.stringify(options).indexOf('common') >= 0) {
          const output = clone(options)

          for (const option in output) {
            if (Object.prototype.hasOwnProperty.call(output, option)) {
              if (output[option]) {
                output[option] = this.replaceOptionLabel(output[option])
              }
            }
          }

          return output
        } else {
          return options || []
        }
      },
      lockedField () {
        if (this.CL) {
          return (
            this.fieldData?.lockedAfterCreatedApplication &&
            this.getForm?.global?.leadType?.value !== 'addCoApplicant' &&
            (
              (this.group === 'applicant' && this.clGetApplicationEvents?.createApplication) ||
              (this.group === 'coApplicant' && this.clGetApplicationEvents?.createApplicationWithCoApplicant) // eslint-disable-line vue/script-indent
            )
          )
        }

        return false
      },
      isSingleStep () {
        return this.getForm?.global?.type?.value === 'singleStep' || this.getForm?.global?.type?.value === 'singleStepPv'
      },
      autocomplete () {
        return this.fieldData.autocomplete === false
          ? 'off'
          : 'on'
      },
      radioSize () {
        return this.projectValentin ? 'large' : 'default'
      },
      projectValentinTooltip () {
        if (this.field === 'children') {
          return {
            title: 'Antal Barn under 21 år',
            text: '<p>Försörjningsplikt är föräldrars eller vårdnadshavares ansvar för sina barns försörjning. Vanligtvis gäller försörjningsplikten upp till att barnet fyller 18 år, men det finns undantagsfall där det även kan gälla fram tills att denne fyller 21 år, beroende på om barnet bor hemma eller inte.</p>',
            trigger: true
          }
        } else if (this.field === 'ssn') {
          return {
            title: 'Personnummer',
            text: '<p>För säkerställande att rätt personnummer anges, inhämtas ditt namn från Statens personadressregister (SPAR). Läs mer om hur vi behandlar dina personuppgifter i vår <a href="https://www.zmarta.se/avtal/dataskyddspolicy" target="_blank">dataskyddspolicy</a>.</p>',
            trigger: true
          }
        }

        return null
      }
    },
    watch: {
      invalid: function (value) {
        if (value) this.invalidTriggered()
      }
    },
    methods: {
      ...mapActions('form', ['onBlur', 'onInput']),
      ...mapActions('mortgage/analytics', ['fieldTrackingMortgage']),
      ...mapActions('cl/lookup', ['resetLookup']),
      ...mapActions('cl/analytics', ['fieldTracking']),
      update () {
        this.modelValue = this.fieldData?.value
      },
      reset () {
        this.modelValue = undefined
      },
      async trackField (data) {
        if (this.VERTICAL === 'cl') {
          await this.fieldTracking(data)
        } else if (this.VERTICAL === 'mortgage') {
          await this.fieldTrackingMortgage(data)
        }
      },
      async invalidTriggered () {
        if (this.lockedField) return

        await this.trackField({
          group: this.group,
          field: this.field,
          options: this.options,
          event: 'error'
        })

        this.$emit('invalid', this.modelValue)
      },
      async focusTriggered () {
        if (this.lockedField) return

        await this.trackField({
          group: this.group,
          field: this.field,
          options: this.options,
          event: 'focus'
        })

        this.focused = true

        this.$emit('focus', this.modelValue)
      },
      async blurTriggered () {
        if (this.lockedField) return

        await this.$nextTick()
        let value = this.fieldData?.value

        // Round to nearest thousand if the formatting is set to 'thousands'
        if (value && this.fieldData?.format === 'thousands') {
          value = Math.round(parseInt(value, 10) / 1000) * 1000
        }

        await this.onBlur({
          group: this.group,
          field: this.field,
          value
        })

        await this.trackField({
          group: this.group,
          field: this.field,
          options: this.options,
          event: 'blur'
        })

        this.modelValue = value
        this.focused = false

        this.$emit('blur', this.modelValue)
      },
      async inputTriggered (value) {
        if (this.lockedField) return

        value = formatField({
          market: this.MARKET,
          format: this.fieldData?.format,
          value
        })

        await this.onInput({
          group: this.group,
          field: this.field,
          value
        })

        await this.trackField({
          group: this.group,
          field: this.field,
          options: this.options,
          event: 'input'
        })

        this.modelValue = value
        this.focused = true

        this.$emit('input', this.modelValue)
      },
      replaceOptionLabel (value) {
        const val = value
          .toString()

        if (!val.includes('common')) return val

        const labelMatch = val.match(/common.(.*)\b/)
        const label = this.common?.[labelMatch?.[1]]

        return val.replace(labelMatch?.[0], label)
      }
    }
  }
</script>
